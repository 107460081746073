export const COMEPETHOME = 'COME PET HOME';
export const NEEDHOME = '모든 동물에게는 집이 필요해요';
export const JOIN1 = '일상과 지출을 가족과 공유하고';
export const JOIN2 = '함께 관리할 수 있어요.';
export const JOIN3 = '다른 동물 친구들의 소식도 들어볼까요?';
export const JOIN4 = '커뮤니티로 즐겁게 확인해요.';
export const JOIN5 = '모든 반려 동물과 함께할 수 있어요.';
export const JOIN6 = '어서오세요, 컴펫홈!';
export const JOIN7 = '모든 반려동물의 집';
export const JOIN8 = '컴펫홈 바로가기';
export const DEFAULT_PROFILE_PATH = '/assets/images/profile-icon-#{random}.svg';
export const PROFILE_MAX = 35;
export const PUSH = '알림';
export const KAKAO = '3초만에 카카오 로그인';
export const NAVER = '네이버 로그인';
export const GOOGLE = '구글로 로그인';
export const APPLE = '애플로 로그인'
export const JOIN = '회원가입';
export const CONGRATULATIONJOIN = '가입을 축하드려요!';
export const HOWCALL = '어떻게 불러드리면 될까요?';
export const NICKNAME = '닉네임';
export const LESSTEN = '10글자 내로 입력해 주세요.';
export const CANUSENICKNAME = '사용 가능한 닉네임 입니다.';
export const ALREADYUSENICKNAME = '이미 사용중인 닉네임입니다.';
export const THERECOMMENDCODE = '추천인 코드를';
export const PLEASEINPUT = '입력해주세요.';
export const SELECT = '선택';
export const NOCODECANUSE = '코드가 없어도 컴펫홈 서비스를 이용하실 수 있어요.';
export const RECOMMENDCODE = '추천인 코드';
export const EXACTLYINPUTCODE = '코드명을 정확히 입력해 주세요.';
export const NEXT = '다음';
export const WRONGCODE = '잘못된 코드입니다.';
export const PLEASEINPUTONEMORETIME = '다시 한 번 입력해주세요.';
export const JUMP = '넘어가기';
export const PLEASEAGREETERMS = '약관에 동의해 주세요.';
export const INFOANDSERVICE = '여러분의 개인정보와 서비스 이용 권리'
export const GOODKEEP = '잘 지켜드릴게요.';
export const ALLAGREE = '모두 동의';
export const REQUIRE = '필수';
export const SEEALL = '보기';
export const MOREAGE = '만 14세 이상입니다.';
export const SERVICE_TERMS = '서비스 이용약관';
export const SERVICE_TERMS_SHORT = '이용약관';
export const PRIVACY_TERMS = '개인정보 처리방침';
export const LOCATION_TERMS = '위치기반 서비스 이용약관';
export const AGREESERVICETERMS = '서비스 이용약관 동의';
export const AGREEMARKETING = '마케팅 수신 동의';
export const AGREEINFO = '개인정보 처리방침 동의';
export const INTERESTANIMAL = '관심있는 동물을';
export const PLEASESELECT = '선택해주세요.';
export const CANSELECTANIMAL = '한 마리 이상의 동물을 선택할 수 있어요.';
export const ANIMALSTORYSEECOMMUNITY = '선택한 동물 소식을 커뮤니티에서 확인할 수 있어요.';
export const NOTSELECTBUTCANSEE = '선택하지 않은 동물 소식도 커뮤니티-전체보기로 볼 수 있어요.';
export const SELECTALL = '전체선택';
export const PLEASECHECKMYAREA = '내 지역을 확인해주세요.';
export const SOTONGINAREA = '내 지역의 반려인들과 커뮤니티에서 소통해요.';
export const UPDATEAREA = '주소 수정';
export const NEEDSETAREA = '내 지역 설정이 필요해요';
export const SELECTAREA = '지역 선택하기';
export const SELECTMYAREA = '지역 선택';
export const INTERESTAREA = '관심 지역을';
export const OTHERAREACANSEE = '다른 지역의 소식도 한 번에 확인할 수 있어요.';
export const MYAREA = '내 지역';
export const OTHERAREA = '관심 지역';
export const LIMITTWO = '최대 2개';
export const AREA_LIMIT = 2;
export const JOININFOCHANGE = '선택해주신 정보는';
export const JOININFOCHANGE2 = '언제든지 변경하실 수 있어요.';
export const SLIDE = '슬라이드';
export const VALIDNICKNAME = '닉네임은 10글자 이하로 설정해 주세요.';


export const MYHISTORY = '내 활동 내역';
export const PROFILE = '프로필 이미지';
export const MYWRITE = '내가 쓴 글';
export const NOCONTENT = '내용이 없습니다.';
export const NEIGHBORPROFILE = '이웃보기';
export const ADDNEIGHBORS = '이웃추가';
export const COMEPETHOMEACT = '컴펫홈 활동';
export const WHAT = '어떤 소식이 궁금하세요?';
export const SEARCH2 = '커뮤니티에서 선택한 선호동물에 대해 검색돼요.';
export const SEARCH3 = '수첩 검색이 필요하다면 수첩으로 이동해야 해요.';

export const MODALALLAREA = '전체지역';
export const MODALINTERESTAREA = '관심지역';
export const ALLCATEGORY = '전체 카테고리';
export const SELECTCATEGORY = '카테고리';
export const ALLANIMAL = '전체 선호동물';
export const SELECTANIMAL = '선호동물';
export const AREA = '지역을 선택해 주세요.';
export const AREA1 = '전체지역과 관심지역이 뭔가요?';
export const AREA2 = '을 선택하면 장소에 관계없이 컴펫홈의 모든 글을 볼 수 있어요';
export const AREA3 = '은 내가 사는 곳과, 내가 사는 곳은 아니지만 관심있는 지역의 글을 볼 수 있어요.';
export const AREA4 = '처음 설정했던 정보를 변경하고 싶다면 FAQ';
export const MODALANIMAL = '선호동물을 선택해 주세요.';
export const MODALANIMAL1 = '선택하신 선호 동물은 프로필 설정에 바로 반영돼요.';
export const SELECTSUBJECT = '카테고리를 선택해 주세요.';
export const HELPWHAT = '어떤도움이 필요한가요?';
export const CANMULTI = '중복 선택이 가능해요.';
export const CANTWRITE = '앗! 작성이 불가해요.';
export const EXPLAIN = '"찾는중" 말머리는 일주일에 한 번만 작성하실 수 있어요.';

export const WARNING = '부적절한 언어를 사용했어요.';
export const DONTDOTHAT = '컴펫홈에서는 모두가 즐거운 커뮤니티를 위해 노력하고 있어요. 부적절한 언어의 수정이 필요해요.';
export const LIMITUSER = '이용이 제한되었어요.';
export const LIMITUSER2 = '커뮤니티 규정 위반이 확인되어, 글쓰기 및 댓글쓰기 기능을 사용하실 수 없습니다.';
export const LIMITUSER3 = '상세한 사항은 커뮤니티 알림의 제재 안내 사항을 확인해 주세요.';
export const WRITER = '작성자';
export const PICTURE = '사진';
export const CANCEL = '취소';
export const APPACCESS = '앱 접근 권한 안내';
export const ONE = '(1개 이상)';
export const AGREE = '동의';
export const ACCESS = '컴팻홈은 아래와 같은 접근 권한을 필요로 합니다. 아래 항목에 동의하지 않아도 서비스를 이용하실 수 있으나, 일부 기능 사용에 제한이 있을 수 있습니다.';
export const ACCESS2 = '- 커뮤니티 및 가계부 내 메모장 사진 촬영';
export const ACCESS3 = '- 사진 이미지를 가져와 게시글에 첨부';
export const ACCESS4 = '- 기기에 사진 저장';

export const NORESULT = '검색기록이 없어요.';
export const RECENT = '최근 검색어';
export const NOSEARCHRESULT = '앗! 검색 결과가 없어요.';
export const CHECKKEYWORD = '검색어를 바르게 입력했는지 확인해 보세요.';
export const SEARCH = '검색';
export const NOSEARCHRESULT2 = '검색 기록이 없어요.';
export const KEYWORD_MAX = 4; // 화면에 표시하는 검색어 최대 갯수

export const HOME = '컴펫홈';
export const POPULAR = '실시간 인기글';
export const MAINCOMEPETSELECT = '컴펫홈의 선택!';
export const COMEPETSELECT = '컴펫홈의 선택';
export const MONTHLYCOMEPETHOME = '월간 컴펫홈';
export const NOTICE = '공지';
export const MAIN = '홈';
export const COMMUNITY = '커뮤니티';
export const NOTE = '수첩';
export const DIARY = '일지';
export const WALLET = '가계부';
export const MORE = '더보기';

export const CHECKSTORY = '[컴펫홈] 에 적힌 동물의 이야기를 확인해 보세요';

export const HIDEONEDAY = '하루동안 보지 않기';
export const CHECKEVENT = '이벤트 확인하기';

export const COMPLETE = '완료';
export const REFRESH = '새로고침';

export const CHANGECHECK = '확인완료 변경하기';
export const SHARE = '외부 공유하기';
export const BLOCK_USER = '작성자 차단하기';
export const UNBLOCK_USER = '작성자 차단 해제';
export const SHOW_ONCE_REPLY = '이 댓글만 보기';
export const MODIFY = '수정하기';
export const DELETE = '삭제하기';
export const DELETEALL = '전체 삭제';
export const DELETEONE = '해당 건만 삭제';
export const COMPLAIN = '신고하기';
export const DELETECOMPLETE = '게시글이 삭제되었어요.';
export const COMPLAINCOMPLETE = '신고가 접수되었어요.';
export const COMPLAINCOMPLETESUB = '커뮤니티 가이드에 따라 검토 후 위반 사항 확인 시 조치가 진행될 예정이에요.';
export const WHYCOMPLAIN = '신고 사유를 입력해 주세요.';
export const NOTIFYBLOCKED = '차단 이웃의 게시글입니다.';
export const BACK = '돌아가기';
export const DELETECONFIRM = '정말로 삭제하시겠어요?';
export const DELETECONFIRMSUB = '삭제된 게시글은 복원할 수 없어요.';
export const DELETECONFIRMDIARY = '삭제된 일지는 복원할 수 없어요.';
export const DELETECONFIRMWALLET = '삭제된 가계부는 복원할 수 없어요.';
export const DELETECONFIRMREPLY = '삭제된 댓글은 복원할 수 없어요.';
export const NOREPLY = '아직 댓글이 없어요.';
export const NEWREPLYPLEASE = '첫 댓글을 남겨보세요.';
export const WRITEREPLY = '답글쓰기';
export const WRITEREPLYPLEASE = '댓글을 입력하세요.';
export const MOREREPLY = '답글 더보기';
export const CLOSEREPLY = '답글 접기';
export const DELETEDREPLY = '삭제된 댓글입니다.';
export const DELETEDREREPLY = '삭제된 답글입니다.';
export const COMPLAINEDREPLY = '신고된 댓글입니다.';
export const BLOCKEDREPLY = '차단 이웃의 댓글입니다.';
// export const BLOCKEDREREPLY = '신고된 댓글입니다.';
export const WITHDRAW_USER = '탈퇴한 회원';

export const WRITENEW = '새글쓰기';

export const TITLE_INFO = '제목을 입력하세요';
export const CONTENT_INFO = '내용을 입력해 주세요.';
export const NUMBER_INFO = '숫자만 입력하세요.';
export const ANIMAL_NAME_INFO = '이름을 입력하세요(최대20자).';
export const ANIMAL_BIRTH_INFO = '2003.01.01 형식으로 입력하세요.';
export const ANIMAL_WEIGHT_INFO = '예) 3.23 / 0.75 등';

export const LOGOUT = '로그아웃';
export const WITHDRAW = '회원 탈퇴';
export const VIEWCNT = '조회';
export const REPLY = '댓글';
export const BOARD = '작성글';
export const VISIT = '방문';
export const NEIGHBORS = '관심이웃';
export const BLOCK_NEIGHBORS = '차단이웃';
export const UNBLOCK_NEIGHBORS = '차단해제';
export const LIKE = '좋아요';
export const GRADE = '등급';
export const CHECK = '확인';
export const CLOSE = '닫기';
export const SAVE = '저장';
export const SAVE_ACTION = '등록하기';
export const ANIMAL = '동물';
export const NAME = '이름';
export const BIRTHDAY = '생일';
export const WEIGHT = '무게';
export const WEIGHT_UNIT = 'kg';
export const SEX = '성별';
export const MALE = '남아';
export const FEMALE = '여아';
export const DATE = '날짜';
export const CONTENT = '내용';
export const DATE_INFO = '날짜선택';
export const ANIMAL_INFO = '동물선택';
export const CAMERA = '사진 촬영';
export const CAMERA2 = '카메라';
export const ALBUM = '사진 앨범';
export const DELETE_PHOTO = '사진 삭제';
export const ADD = '추가하기';
export const CHANGE = '변경하기';
export const SUM = '합계';
export const EXPEND = '지출';
export const BUDGET = '예산';
export const CATEGORY = '분류';
export const ASSET = '자산';
export const USENAME = '사용처';
export const AMOUNT = '금액';
export const FAQ = 'FAQ';

export const MYPETINFO = '가족을 선택해 주세요.';

export const LEVEL1 = '전입신고';
export const LEVEL2 = '동네주민';
export const LEVEL3 = '골목대장';
export const LEVEL4 = '마을이장';
export const LEVEL5 = '터줏대감';
export const LEVEL6 = '기고가';
export const LEVEL7 = '보호소';
export const LEVEL8 = '스텝';
export const LEVEL9 = '고인물'; //골목대장 선착 1000명 진입한 사람


export const TIME_AM = '오전';
export const TIME_PM = '오후';
export const ALL = '전체';

export const PETAGE = '세';
export const PETAGEMONTH = '개월';

export const MONTH_MEMORY = '이번 달의 추억';
export const ALL_MEMORY = '지금까지 추억';

export const DIARYADD = '일지쓰기';
export const DIARYEMPTY1 = '작성된 일지가 없어요.';
export const DIARYEMPTY2 = '오늘의 기록을 시작해볼까요?';
export const DIARYEMPTY = '오늘의 일지를 등록해보세요!';
export const WALLETSETTING = '수첩 설정';
export const PET_FREE_ADD_CNT = 3;
export const MY_PET_MAX = 10;
export const WALLET_MY_PET = '수첩 등록 식구';
export const ADD_PET_INFO = '컴펫홈과 함께할 가족을 알려주세요.';
export const BTN_ADD_PET = '반려가족 추가';
export const BTN_NEED_PRO = 'PRO 필요';
export const ADDMYPET = '반려동물 등록';
export const CHOICEANIMAL = '동물을 선택해 주세요.';
export const CHOICEANIMALTYPE = '종류 선택';

export const WALLETADD = '가계부쓰기';
export const WALLETVALIDATION = '설정할 수 있는 최대 금액은 "99,999,999,999"원입니다.'

export const ANALYSIS = '분석';
export const STATISTICS = '통계';

export const NOTE_SEARCH_INFO1 = '수첩 안의 내용만 검색돼요.';
export const NOTE_SEARCH_INFO2 = '커뮤니티 검색이 필요하다면 커뮤니티로 이동해야 해요.';
export const NOTE_SEARCH_INFO3 = '검색은 어떻게 하나요? FAQ';

export const WALLETSHAREGIVE = '수첩 공유하기';
export const WALLETEMPTY1 = '작성된 지출이 없어요.'
export const WALLETEMPTY2 = '오늘의 가계부를 시작해볼까요?';
export const WALLETEMPTY = '오늘의 가계부를 등록해보세요!';
export const SHARE_INFO1 = '가족과 수첩을 함께해요.';
export const SHARE_INFO2 = '편리하게 초대할 수 있어요.';
export const SHARE_INFO3 = '함께할 상대방에게 아래 코드를 전달해 주세요.';
export const SHARE_FREE_INFO1 = '아직 상대방을 초대할 수 없어요.';
export const SHARE_FREE_INFO2 = '컴펫홈 PRO';
export const SHARE_FREE_INFO3 = '를 통해 초대 코드를 발급할 수 있어요!';
export const SHARE_FREE_NEED1 = '다양한 기능이 준비되어 있어요.';
export const SHARE_PRO_NEED = '더 많은 인원 초대가 필요하신가요?';
export const COPYCODE = '초대 코드 복사';
export const OPENCONTACT = '연락처 열기';
export const COPY_INFO1 = '추천 코드가 복사되었습니다.';
export const COPY_INFO2 = '다른 곳에 복사 붙여넣기가 가능합니다.';
export const INVITE = '초대하기';

export const WALLETSHARETAKE = '수첩 공유받기';
export const WALLETSHARETAKE_CODE = '수첩 공유받기 (공유코드 입력)';
export const ADD_CODE_INFO = '공유 코드 입력';
export const ADD_CODE_INFO2 = '공유받은 코드를 입력해 주세요.';
export const SHARE_T_INFO1 = '가족과 수첩을 함께해요.';
export const SHARE_T_INFO2 = '편리하게 공유받을 수 있어요.';
export const SHARE_T_QNA1 = '기존 가계부는 어떻게 되나요?';
export const SHARE_T_QNA2 = '이전에 이용하고 있던 자신의 수첩 데이터는 삭제되어요.';
export const SHARE_T_QNA3 = '삭제된 수첩 데이터는 복원이 어려우니, 이용하고 있던 수첩 내역이 있다면 공유 받기 전';
export const SHARE_T_QNA4 = '데이터 백업';
export const SHARE_T_QNA5 = '을 추천드려요.';
export const SHARECODE_MAX_LENGTH = 6;
export const VALIDSHARECODE = '공유코드는 영문 또는 숫자로 입력해 주세요.';
export const VALIDLENGTHSHARECODE = '공유코드는 6자리까지 입력 가능해요.';
export const SHARE_FAIL_400 = '잘못된 코드입니다.';
export const SHARE_FAIL_400_sub = '공유 코드를 다시 한 번 입력해 주세요.';
export const SHARE_FAIL_450 = '이미 연동된 코드입니다.';
export const SHARE_FAIL_450_sub = '이미 해당 수첩에 연동되어 있어요.';
export const SHARE_FAIL_420 = '이미 연동된 수첩이 있어요.';
export const SHARE_FAIL_420_sub = '기존 수첩 연결을 먼저 해지해주세요.';
export const SHARE_FAIL_410 = '앗! 공유 인원이 초과됐어요.';
export const SHARE_FAIL_410_sub = '안타깝지만 공유 인원이 초과되어 연동이 불가해요.';
export const SHARE_FAIL3 = '다시 입력하기';
export const SHARE_SUCCESS1 = '환영합니다!';
export const SHARE_SUCCESS2 = '수첩 연동이 완료되었습니다.';
export const SHARE_SUCCESS3 = '수첩 둘러보기';
export const SYNC = '연동하기';
export const SHARE_WALLET_EXPLAIN = '가족, 친구, 지인들과 함께 수첩을 공유해서 사용할 수 있어요. 동물과 함께하는 우리의 일상을 수첩에 쌓아가다보면 반려동물뿐만 아니라 서로를 더 이해할 수 있을거에요.';
export const SHARE_WALLET_EXPLAIN2 = '공유하고 싶은 인원에 따라 플랜을 선택할 수 있어요. 더 자세한 내용은';
export const SHARE_WALLET_FAQ = '수첩은 어떻게 공유하나요? FAQ';

export const WALLETMEMBER = '공유 구성원 관리';
export const NOMEMBERPLAN = '멤버십 이용 내역이 없습니다.';
export const OWNER = '모임주';
export const MEMBER = '구성원';
export const UNSHARE = '공유끊기';
export const UNSHARE_OK = '해제하기';
export const UNSHARE_INFO1 = '정말로 공유를 해제하시나요?';
export const UNSHARE_INFO2 = '해제 즉시 수첩 데이터가 분리됩니다.';
export const UNSHARE_COMPELTE = '공유가 해제되었습니다.';
export const INVITE_MEMBER = '구성원 초대하기';

export const WALLETBUDGET = '예산 설정';
export const BUDGET_INFO1 = '한 달 예산을 입력해주세요.';
export const BUDGET_INFO2 = '컴펫홈 가계부의 예산은 한 달 단위로 설정할 수 있어요.';
export const BUDGET_INFO3 = '매월 1일을 기준으로 예산을 확인해요.';
export const BUDGET_INFO4 = '예산 금액 입력';
export const SETTING = '설정하기';

export const WALLETASSET = '자산 설정';
export const ASSET_MAX = 10;
export const ASSET_MAX_INFO = '최대 10개의 자산을 설정할 수 있어요!';
export const ASSETADD = '자산 추가하기';
export const ASSET_INFO1 = '다양한 자산을 추가해 가계부를 관리해 보세요.';
export const WALLETASSETADD = '자산 추가';
export const ASSET_ADD_INFO1 = '추가할 자산의 별명을 입력해 주세요.';
export const WALLETASSETMODIFY = '자산 별명 변경';
export const ASSET_MODIFY_INFO1 = '변경할 별명을 입력해 주세요.';
export const ASSET_ADD_INFO2 = '별명 입력';
export const ASSET_ADD_INFO3 = '별명을 입력하세요.(최대 10자)';

export const WALLETEXPEND = '지출 분류 설정';
export const EXPEND_MAX = 20;
export const EXPEND_MAX_INFO = '최대 20개의 분류를 설정할 수 있어요!';
export const EXPENDADD = '분류 추가하기';
export const EXPEND_INFO1 = '다양한 자산을 추가해 가계부를 관리해 보세요.';
export const WALLETEXPENDADD = '분류 추가';
export const ASSET_EXPEND_INFO1 = '추가할 분류명을 입력해 주세요.';
export const WALLETEXPENDMODIFY = '분류 수정';
export const EXPEND_MODIFY_INFO1 = '수정할 분류명을 입력해 주세요.';
export const ASSET_EXPEND_INFO2 = '분류명 입력';
export const ASSET_EXPEND_INFO3 = '분류명을 입력하세요.(최대 10자)';


export const RECENT_BACKUP_DATE = '최근 백업 일시';

export const WALLETDATAEXPORT = '데이터 저장하기';
export const EXPORT_INFO1 = '원하는 시점의';
export const EXPORT_INFO2 = '데이터를 저장해요';
export const EXPORT_INFO3 = '편리하게 서버로 데이터를 백업해 보세요.';
//export const EXPORT_SELECT = '전송 데이터 선택';
//export const EXPORT_SELECT_INFO = '일지 데이터 중 사진은 백업에 포함되지 않습니다. (텍스트 전송)';
export const EXPORT_NODATA1 = '저장된 데이터가 없어요.';
export const EXPORT_NODATA2 = '데이터는 일지 구성원 모두가 저장할 수 있어요.';
export const EXPORT_BFR_INFO = '저장 전 유의사항';
export const EXPORT_BFR_INFO2 = '복원 데이터는 가장 최근 저장한 시점의 데이터 1개만 저장할 수 있어요.';
export const EXPORT_BFR_FAQ = '데이터 관리 FQA 바로가기';
export const EXPORT = '저장하기';

export const WALLETDATAIMPORT = '데이터 복원하기';
export const IMPORT_INFO1 = '원하는 시점의';
export const IMPORT_INFO2 = '데이터를 복원해요.';
export const IMPORT_INFO3 = '편리하게 서버로 데이터를 백업해 보세요.';
// export const IMPORT_SELECT = '복원 데이터 선택'; 
// export const IMPORT_SELECT_INFO = '내 기기에 저장된 파일을 불러와요.';
export const IMPORT_NODATA1 = '저장된 데이터가 없어요.';
export const IMPORT_NODATA2 = '데이터를 저장한 뒤 복원할 수 있어요.';
export const IMPORT_BFR_INFO = '복원 전 유의사항';
export const IMPORT_BFR_INFO2 = '복원 시점 이전 데이터는 복구할 수 없어요. 신중히 복원하여 주세요!';
export const IMPORT_BFR_FILEDOWN = '데이터 양식 다운로드 FAQ 바로가기';
export const IMPORT_BFR_INFO3 = '데이터는 기존의 기록 위에 덮어씌우는 형태로 복원되어요.';
export const IMPORT_BFR_INFO4 = '데이터 복원을 진행하면 이전 기록은 볼 수 없어요.';
export const IMPORT_BFR_INFO5 = '이미 적어둔 추억이 있다면 데이터 백업을 진행한 후 복원을 권장드려요.';
export const IMPORT_BFR_FAQ = '데이터 관리 FQA 바로가기';
export const DATASELECT = '데이터 선택하기';
export const RECOVER = '복원하기';

export const WALLETINITIAL = '수첩 초기화';
export const INITAL_INFO1 = '수첩의 모든 내용과 설정을';
export const INITAL_INFO2 = '처음 상태로 되돌려요.';
export const INITAL_BFR_INFO = '초기화 유의사항';
export const INITAL_BFR_INFO2 = '컴펫홈은 개인정보보호를 위해, 삭제가 요청된 데이터를 별도로 보관하지 않아요.';
export const INITAL_BFR_INFO3 = '초기화를 하면 기록을 다시 복원할 수 없어요.';
export const INITAL_BFR_INFO4 = '남겨둘 추억이 있다면 데이터 백업을 진행한 후 초기화를 권장드려요.';
export const INITAL_BFR_FAQ = '초기화 FAQ 확인하기';
export const INITAL = '수첩 초기화하기';

export const PROFILEMANAGE = '프로필 설정';
export const INTERESTANIMAL_SETTING = '관심동물 설정';
export const NICKNAMEMANAGE = '닉네임 설정';
export const NICKNAMESETTING_INFO1 = '불리는 이름을';
export const NICKNAMESETTING_INFO2 = '다르게 설정할 수 있어요.';
export const AREAMANAGE = '지역 설정';
export const AREASETTING_INFO1 = '선택한 지역을';
export const AREASETTING_INFO2 = '다른 곳으로 변경할 수 있어요.';
export const AREASETTING_INFO3 = '관심 지역은 선택 사항이에요.';
export const AREASETTING_INFO4 = '현재 위치를 확인해주세요.';
export const AREASETTING_INFO5 = '지금 계신 곳의 위치를 빠르게 확인해 드려요.';

export const NOTICEEVENT = '공지/이벤트';

export const ACCOUNTMANAGE = '계정 관리';
export const ACCOUNT_SOCIAL = '소셜 로그인 연동';
export const ACCOUNT_RECOMMEND = '나의 추천 코드';
export const COPY_RECOMMEND = '코드 복사하기';
export const ASK_LOGOUT = '로그아웃 하시겠어요?';
export const ASK_LOGOUT_INFO = '언제든 다시 돌아오실 수 있어요.';
export const WITHDRAW_COMPLETE = '회원 탈퇴가 완료되었습니다.';
export const WITHDRAW_INFO = '다시 만날 날을 기대할게요.';

export const SUBSCRIBEMANAGE = '구독 관리';
export const MEMBERSHIPMANAGE = '멤버십 관리';
export const PRODUCT = '이용 상품';
export const DUEDATE = '이용 기간';
export const FINISH_DUEDATE = '멤버십 이용이 아닙니다.';
export const CHECKMEMBER = '구성원 확인하기';
export const GOODS_INFO1 = '프리미엄 기능으로';
export const GOODS_INFO2 = '더 편리한 수첩을 작성해봐요';
export const GOODS_INFO3 = '혼자, 그리고 같이.';
export const GOODS_INFO4 = '다양한 플랜이 준비되어 있어요';
export const MONTHLY = '월간';
export const YEARLY = '연간';
export const SALE = '할인';
export const MONTHLY_PAY = '월 환산';
export const BTN_SUBSCRIBE = '구독하기';
export const BTN_CHANGE_SUBSCRIBE = '변경하기';
export const ASK_UNSUBSCRIBE = '컴펫홈 멤버십을 해지하고 싶으신가요?';
export const UNSUBSCRIBE = '구독 해지';
export const ALREADY_SUBSRICE = '기존에 결제한 구독료는 어떻게 되나요?';
export const ALREADY_SUBSRICE_INFO1 = '기존에 결제한 구독료는요?';
export const ALREADY_SUBSRICE_INFO2 = '기존에 결제한 멤버십 구독료는 남은 구독 기간을 일할 계산해서 사용하셨던 결제 수단으로 스토어를 통해 자동 환불돼요.';

export const NOTIFICATIONMANAGE = '알림 설정';
export const DISTURB_SETTING = '방해 금지 시간 설정';
export const DISTURB_START_TIME = '방해 금지 시작 시간';
export const DISTURB_END_TIME = '방해 금지 종료 시간';

export const FAQ_INFO = '컴펫홈 FAQ로 궁금증을 해결해보세요!';
export const HELP_INFO = '다른 도움이 필요하신가요?';
export const HELP = '문의하기';

export const SERVICEINFO = '서비스 정보';

export const COUPON_INPUT = '쿠폰 입력';
export const COUPON_INFO1 = '쿠폰 코드를';
export const COUPON_INFO2 = '입력해주세요.';
export const COUPON_INFO3 = '특별한 쿠폰 코드로 컴펫홈을 즐겨보세요.';
export const COUPON_CODE = '쿠폰 코드';
export const COUPON_CODE_INFO = '코드명을 정확히 입력해주세요.';
export const COUPON_FAIL1 = '잘못된 코드입니다.';
export const COUPON_FAIL2 = '다시 한 번 입력해 주세요.';
export const COUPON_SUCC1 = '정상 등록되었습니다.';

export const TRIAL_SINGLE_PLAN = '나홀로 멤버십 (무료 이용중)';
export const USER_PAY_NOTICE_STR = '유료 회원 기능입니다.';
export const INFO = '다양한 기능이 준비되어 있어요.';
export const BTN_SUBSCRIBE_PLAN = '구독 플랜 확인';
export const BTN_CHANGE_PLAN = '구독 플랜 변경';

// 멤버십 최대 인원 (오너 제외)
export const PLAN1_MAX_MEMBER = 0;
export const PLAN2_MAX_MEMBER = 1;
export const PLAN3_MAX_MEMBER = 4;

// 멤버십 아이콘
export const PLAN1_IMG_SRC = '/assets/images/icon-33.svg'
export const PLAN2_IMG_SRC = '/assets/images/icon-34.svg'
export const PLAN3_IMG_SRC = '/assets/images/icon-41.svg'

export const PC_MAX_WIDTH = '720px';
export const MOBILE_MAX_WIDTH = '719px';

export const IMG_MAX_SIZE = 5;
export const IMG_MAX_WIDTH = 800;
